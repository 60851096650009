import React, { useEffect, useState } from 'react'
import { baseUrl } from "../config";
import { Link, useNavigate } from 'react-router-dom';
import { PDFFillScreen } from './PDFFillScreen';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';

const MyOrders = ({lang}) => {
  const navigate = useNavigate();
  const [myOrderList, setMyOrderList] = useState([]);
  var myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem('token'));
  var requestOptions = { method: "POST",  headers: myHeaders,
  redirect: "follow" };
  const [inputs, setInputs] = useState(null);
  const [Order, setOrder] = useState(null);
  const [product, setProduct] = useState(null);

  const getProduct = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/parts/readById/${Order.form_id}`, { headers: { "Content-Type": "application/json" } });
      setProduct(response.data);
    } catch (error) { console.log(error); }
  };
  const getOrder = (id) => {
    fetch(`${baseUrl}/api/order/readById/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let r=JSON.parse(result);
        if (r.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          setTimeout(() => { navigate("/login"); }, 10);
        }
        else
        {
          let data = JSON.parse(result).data.order_data;
          setOrder(JSON.parse(result).data);
         
          

        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if(Order!=null){
      getProduct();
      // console.log(inputs)
      // PDFFillScreen(Order,inputs)
    }
  }, [Order]);
  useEffect(() => {
    if(product!=null){
      let d=[];
      Order.order_data.map((item) => {
        d.push({name:item.pcfv.value,value:item.value,pdfName:item.pdfName })
        // console.log(d)
        
        return null;
      });
      
      setInputs(d);
    }
  }, [product]);
  useEffect(() => {
    if(inputs!=null){
      fillPdfForm();
    }
  }, [inputs]);
  const fillPdfForm = async () => {
    var pdfUrl = '/pdf/i-130a.pdf';

    if(product.id==79) {  pdfUrl = '/pdf/i-765.pdf';}
    if(product.id==80) {  pdfUrl = '/pdf/i-130.pdf';}
    if(product.id==81) {  pdfUrl = '/pdf/i-130a.pdf';}
    if(product.id==82) {  pdfUrl = '/pdf/i-131.pdf';}
    if(product.id==83) {  pdfUrl = '/pdf/i-485.pdf';}
    if(product.id==84) {  pdfUrl = '/pdf/i-589.pdf';}
    if(product.id==85) {  pdfUrl = '/pdf/i-864.pdf';}


    try {
      const pdfBuffer = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBuffer, { ignoreEncryption: true });

      const form = pdfDoc.getForm();
      const fields = form.getFields();
      if (fields.length > 0) {
        fields.forEach((field) => {

          try {
          const name = field.getName();

          console.log(name);
             
           let v="";
            try {
            v=inputs.find(x=>x.pdfName==name).value;
            } catch (error) {
            }
            try {
            field.setText(v);
              
            } catch (error) {
             
            }
            try {

              let vq="";
            try {
            vq=inputs.find(x=>x.pdfName==name).pdfName;
            } catch (error) {
            }
              if(name==vq)
            {
            field.check();
            }
            } catch (error) {
            }
           
            try {
              if(v!=null)
            {
            field.select(v);
            }
            } catch (error) {
              
            }
         
            
            
          } catch (error) {
            console.log(error)
          }
          
        });
      } else {
        throw new Error('No fields found in the PDF form.');
      }
      const filledPdfBuffer = await pdfDoc.save();
      // window.download(filledPdfBuffer, "pdf-lib_form_creation_example.pdf", "application/pdf");

      openFilledPdfInNewTab(filledPdfBuffer);
    } catch (error) {
      console.error('Error filling PDF form:', error);
    }
  };
 
    const openFilledPdfInNewTab = (pdfBuffer) => {
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl,'_blank');
    };
  const orderList = () => {
    fetch(`${baseUrl}/api/order/read`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result)
        setMyOrderList(result.data);
        if (result.status == 401) {
          localStorage.removeItem("token");
          setTimeout(() => { navigate("/login"); }, 10);
        }
      })
      .catch((error) => {
        console.log("error", error)
      });
  }

  useEffect(() => {
    orderList();
  }, [])

  return (
    <div className='container my-5 pt-3'>
    <div className='witr_section_title_inner text-center'>
      <h3 className='my-5'>
      {lang=="es"?'Mi aplicación':'My Application'}

      </h3>
      </div>
      <div className='card rounded-0'>
      <table className="table card-body">
        <thead class="table-brand">
          <tr>
            <th scope="col">#</th>
            <th scope="col">
      {lang=="es"?'Forma':'Form'}

            </th>
            <th scope="col">
      {lang=="es"?'Acción':'Action'}

            </th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          {Array.isArray(myOrderList) && myOrderList.map(item => (
            <tr key={item.id}>
              <td>#F000{item.id}</td>
              <td>{item.part.title} {item.status=="Draft" &&(<>(<Link to={'/forms/' + item.form_id} className="text-warning" style={{ fontSize: '18px',textDecoration:"underline" }}>
      {lang=="es"?'Continua editando':'Continue Editing'}
      </Link>)</>)}</td>
                {/*<span className={item.status.toLowerCase().replace(/\s+/g, '-') + '-status'}>{item.status}</span>*/}
              <td>
              <Link onClick={()=>{getOrder(item.id)}}   >
              <i className="mx-2 fa-solid fa-print text-danger"></i></Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {myOrderList.length == 0 && (
        <div>
          <div className='card rounded-0'>
            <div className='card-body'>
              <h3 className='text-center'>
      {lang=="es"?'ningún record fue encontrado':'No Record Found'}

              
              </h3>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  )
}

export default MyOrders