
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export default function Select2({colSize, dependent_name,dependent_value,id,title, name, options,onChange, handleChange, inputs,dependentList }) {
  const selectedOption =inputs!=null && options.find(option => option.value === inputs[name]) || null;
  const [hiddenClass,setHiddenClass]=useState();

  const handleSelectChange = (selectedOption) => {
    // Extract the value from the selected option
    const selectedValue = selectedOption ? selectedOption.value : null;
    onChange!=null&&onChange(selectedValue);
    inputs!=null && handleChange({ target: { name, value: selectedValue } });
    
  };

  
  useEffect(()=>{
    if(dependent_name!=null && inputs!=null)
    
    if(inputs[dependent_name]==dependent_value)
    {setHiddenClass('')}
    else{setHiddenClass('hidden')}
  },[inputs])
   colSize= colSize==null?"col-md-12":colSize;
  const selectClass = `${colSize} ${hiddenClass}`;


  return (
    <div className={selectClass}>
    <div className='mui-input-wrapper'>
      <label className="mui-label">
        {title}
      </label>
      <Select
        name={name}
        className="basic-single"
        classNamePrefix="select"
        onChange={handleSelectChange}
        value={selectedOption}
        options={options}
      />
    </div>
    </div>
  );
}
