import React, { useState } from 'react'
import { baseUrl } from '../../config';

const Contact = ({lang}) => {
  const [name,setName]=useState(null);
  const [email,setEmail ]=useState(null);
  const [comment,setComment ]=useState(null);

  
  const postMessage = () => 
  {

     if(name==null)
     {
      alert('Please Enter your Name');
      return false;
     }
     if(email==null)
     {
      alert('Please Enter your Email');
      return false;
     }
     if(comment==null)
     {
      alert('Please write some Comment');
      return false;
     }
      var raw = JSON.stringify({
        name:name,
        email:email,
        comment:comment
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      fetch(`${baseUrl}/api/postMessage`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result=JSON.parse(result)
          console.log(result)
            if(result.status==200){
              setName('');
              setEmail('');
              setComment('');
                alert('Your Message has been Posted Successfully!')
               
            }
        })
        .catch((error) => console.log("error", error));
  }
  return (
    <div className='my-5 py-5 mx-5'>
    <section
    className="elementor-section elementor-top-section elementor-element elementor-element-62db33c elementor-section-full_width elementor-section-height-default elementor-section-height-default"
    data-id="62db33c"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div className="elementor-row">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fe18a6f"
          data-id="fe18a6f"
          data-element_type="column"
        >
          <div className="elementor-column-wrap elementor-element-populated">
            <div className="elementor-widget-wrap">
              <div
                className="elementor-element elementor-element-79a7b77 hisfull-star-rating--align-left elementor-widget elementor-widget-witr_section_apartment"
                data-id="79a7b77"
                data-element_type="widget"
                data-widget_type="witr_section_apartment.default"
              >
                <div className="elementor-widget-container">
                  <div className="apartment_area">
                    <div className="apartment_text">
                      {/* title */}
                     {/*  <h1>24/7 Hours Support</h1>*/}
                      {/* content */}
                      <h2>
                      {lang=="es"?'Consulta gratis':'Free Consultation'}
                       </h2>
                    </div>
                    <div className="witr_apartment_form">
                      <div
                        className="wpcf7 js"
                        id="wpcf7-f1989-p1717-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div className="screen-reader-response">
                          <p
                            role="status"
                            aria-live="polite"
                            aria-atomic="true"
                          />{" "}
                          <ul />
                        </div>
                        
                          <div style={{ display: "none" }}>
                            <input
                              type="hidden"
                              name="_wpcf7"
                              defaultValue={1989}
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_version"
                              defaultValue="5.7.4"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_locale"
                              defaultValue="en_US"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_unit_tag"
                              defaultValue="wpcf7-f1989-p1717-o1"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_container_post"
                              defaultValue={1717}
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_posted_data_hash"
                              defaultValue=""
                            />
                          </div>
                          <div className="witr_form_area2 witr_2width100">
                            <div className="witr_form_controls2">
                              <div className="witr_field2">
                                <div className="witr_form_field2 witr_2width100">
                                  <p>
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="text-19"
                                    >
                                      <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                        aria-required="true"
                                        aria-invalid="false"
                                       placeholder={lang=="es"?'Introduzca su nombre*':'Enter your name*'}
                                        defaultValue=""
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={(x)=>{setName(x.target.value)}}
                                      />
                                    </span>
                                  </p>
                                </div>
                                <div className="witr_form_field2 witr_2width100">
                                  <p>
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="email-380"
                                    >
                                      <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                        aria-required="true"
                                        aria-invalid="false"
                                       placeholder={lang=="es"?'Introduce tu correo electrónico*':'Enter your email*'}

                                        defaultValue=""
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(x)=>{setEmail(x.target.value)}}
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                             {/* <div className="witr_field2">
                                <div className="witr_form_field2 witr_2width100">
                                  <p>
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="menu-846"
                                    >
                                      <select
                                        className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required witr_select"
                                        aria-required="true"
                                        aria-invalid="false"
                                        name="menu-846"
                                      >
                                        <option value="Select subject*">
                                          Select subject*
                                        </option>
                                        <option value="Cottage Cheese">
                                          Cottage Cheese
                                        </option>
                                        <option value="Organic Milk">
                                          Organic Milk
                                        </option>
                                        <option value="Cottage Cream">
                                          Cottage Cream
                                        </option>
                                      </select>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            */}
                              <div className="witr_field2">
                                <div className="witr_text_area2">
                                  <p>
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="textarea-234"
                                    >
                                      <textarea
                                        cols={40}
                                        rows={10}
                                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                        aria-required="true"
                                        aria-invalid="false"
                                       placeholder={lang=="es"?'Ingresa tu comentario*':'Enter your Comment*'}

                                        name="comment"
                                        defaultValue={""}
                                        value={comment}
                                        onChange={(x)=>{setComment(x.target.value)}}
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="witr_field2">
                                <div className=" witr_con_btn2">
                                  <p>
                                    <button
                                      className="w_btn2"
                                      onClick={postMessage}
                                    >
                                    {lang=="es"?'Enviar mensaje':'Send Message'}

                                      {" "}
                                      <i className="icofont-long-arrow-right" />
                                    </button>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div
                            className="wpcf7-response-output"
                            aria-hidden="true"
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-38ff5d4"
          data-id="38ff5d4"
          data-element_type="column"
        >
          <div className="elementor-column-wrap elementor-element-populated">
            <div className="elementor-widget-wrap">
              <div
                className="elementor-element elementor-element-27b3b6f elementor-widget elementor-widget-witr_section_Shape"
                data-id="27b3b6f"
                data-element_type="widget"
                data-widget_type="witr_section_Shape.default"
              >
                <div className="elementor-widget-container">
                  <div className="witr_shape_item">
                    <div className="witr_shape_item_inner">
                      <div
                        className="witr_shape_image "
                        style={{
                          animation:
                            "witr_movelr_box45  5s  linear  1s  infinite  alternate  running"
                        }}
                      >
                        {/* image */}
                        <img
                          decoding="async"
                          src="/from.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}

export default Contact