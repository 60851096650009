import React from 'react'
import { Link } from 'react-router-dom'

const Slider = ({lang}) => {
  return (
    <div className='slider'>
      <div className="template-home-wrapper">
        <div
          className="elementor elementor-2018"
        >
          <div className="elementor-inner">
            <div className="elementor-section-wrap">
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-f4f866e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
              
              >
             
                <div
                  className="elementor-background-slideshow swiper-container swiper-container-initialized swiper-container-vertical swiper-container-rtl"
                  dir="rtl"
                  style={{ height: 1130 }}
                >
                  <div
                    className="swiper-wrapper"
                    style={{
                      transitionDuration: "0ms",
                      transform: "translate3d(0px, -3390px, 0px)"
                    }}
                  >
                    <div
                      className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                      data-swiper-slide-index={0}
                      style={{ height: 1130 }}
                    >
                      
                    </div>
                    <div
                      className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                      data-swiper-slide-index={1}
                      style={{ height: 1130 }}
                    >
                      
                    </div>

                    <div
                      className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev"
                      data-swiper-slide-index={0}
                      style={{ height: 1130 }}
                    >
                     
                    </div>
                    <div
                      className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                      data-swiper-slide-index={1}
                      style={{ height: 1130 }}
                    >
                      <div
                        className="elementor-background-slideshow__slide__image elementor-ken-burns elementor-ken-burns--in"
                        style={{
                          backgroundImage:
                            'url("/images/sliderb.jpg")'
                        }}
                      />
                    </div>
                  </div>
                 
                </div>
                <div
                  className="elementor-shape elementor-shape-bottom"
                  data-negative="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1000 100"
                    preserveAspectRatio="none"
                  >
                    <path
                      className="elementor-shape-fill"
                      d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"
                    />
                  </svg>
                </div>
                <div className="elementor-container elementor-column-gap-no">
                  <div className="elementor-row">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-56bd5c3"
                      data-id="56bd5c3"
                      data-element_type="column"
                    >
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div
                            className="elementor-element elementor-element-8fa73e5 elementor-widget elementor-widget-witr_slick_slider"
                            data-id="8fa73e5"
                            data-element_type="widget"
                            data-widget_type="witr_slick_slider.default"
                          >
                            <div className="elementor-widget-container">
                              {/* slider area */}
                              <div className="witr_ds_content_area witr_dsider_js_active simages_ida4 slick-initialized slick-slider">
                                {/* single slider item */}
                                <div
                                  className="slick-list draggable"
                                  style={{ padding: 0 }}
                                >
                                  <div
                                    className="slick-track"
                                    style={{
                                      opacity: 1,
                                      width: 1420,
                                      transform: "translate3d(0px, 0px, 0px)"
                                    }}
                                  >
                                    <div
                                      className="witr_ds_slider_content witr_slick_content slick-slide slick-current slick-active slick-center"
                                      data-slick-index={0}
                                      aria-hidden="false"
                                      style={{ width: 1420 }}
                                      tabIndex={0}
                                    >
                                      <div className=" witr_ds_content witr_slick_height  text-center witr_current_id-c9ea8b1">
                                        <div className="witr_ds_content_inner container">
                                          {/* title */}
                                          {/*<h1>Developer By Themex</h1>*/}
                                          {/* title 2 */}
                                          <h2>
                                          {lang=="es"?"¡Bienvenido a Swift Immigration!":"Welcome to Swift Immigration!"}
                                          
                                          </h2>
                                          {/* title 3 */}
                                          {/* content */}
                                          <p>
                                          
                        {lang=="es"?'¡Bienvenido a Swift Immigration! Nuestro sitio web ofrece fácil acceso a información de inmigración, llenado de formularios y asistencia personalizada. Estamos aquí para hacer que el proceso de inmigración sea sencillo y sin estrés para usted.':'Welcome to Swift Immigration! Our website offers easy access to immigration information, form filling, and personalized assistance. We are here to make the immigration process smooth and stress-free for you.'}





                                          </p>
                                          {/* btn gradient style */}
                                          <div className="slider_btn">
                                            <div className="witr_btn_style">
                                              <div className="witr_btn_sinner">
                                                {/* button */}
                                                <Link
                                                  className="witr_btn "
                                                  to="/file-form"
                                                  tabIndex={0}
                                                >
                                          {lang=="es"?'Presentar formulario en línea':'File Form Online'}

                                                  
                                                </Link>
                                                {/* video button */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                              {/* end slick slider */}
                              {/* social */}
                              {/* end slider area */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Slider