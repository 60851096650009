import React from 'react'
import Footer from '../layout/Footer'
import Contact from '../component/Home/Contact'

const ContactScreen = ({lang}) => {
  return (
    <div className='my-5 py-5'>
    <Contact lang={lang}/>
    <Footer/>
    </div>
  )
}

export default ContactScreen