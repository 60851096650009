import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { baseUrl } from '../config';

function SignUp() {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the registration page
  };
  const [name, setName] = useState(''); // Add state for the name field
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submit, setSubmit] = useState(false);
  const [responseMessage, setResponseMessage] = useState(''); // New state variable for the response message

  const handleNameChange = (e) => {
    setName(e.target.value); // Handle name input changes
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    setSubmit(true);

    e.preventDefault();

    
      var raw = JSON.stringify({
        name: name, // Include the name in the data object
        email: email,
        password: password,
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      fetch(`${baseUrl}/api/register`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result)
  
            result=JSON.parse(result);
            if(result.status==200){
              setSubmit(false);
              console.log('API Response:', result);
              navigate('/verify/email/'+result.data.id);
            }
            else if(result.status==401)
            {
              if (result.errors && result.errors.email && result.errors.email.length > 0) {
                const emailError = result.errors.email[0];
                setResponseMessage(emailError);
            } else {
                // Handle other cases or set a default message if needed
                setResponseMessage("An error occurred.");
            }              
            }
            else
            {
              setResponseMessage(result.message)
            }
            setSubmit(false);
        })
      
        .catch((error) => {console.log("error", error);setSubmit(false);});


      

      
  };
  return (
    <section className="h-100 gradient-form signup" style={{ backgroundColor: '#eee' }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="text-center my-auto" style={{height:'100%',display:'flex',alignItems:"center"}}>
                      <img
                        src="/logo.png"
                        style={{ width: '400px' }}
                        alt="logo"
                      />
                      
                    </div>
                    
                   
                </div>
                <div className="col-md-6    ">


                <form onSubmit={handleSubmit} className='card-body p-md-5 mx-md-4'>
                <p className='text-center'>Please SignUp to your account</p>
                {responseMessage && (
                  <p className="text-center text-danger">{responseMessage}</p>
                )}
                <div className="form-outline mb-4">
                  <label className="form-label" >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    onChange={handleNameChange}
                    required
                  />

                </div>
                <div className="form-outline mb-4">
                  <label className="form-label" >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email address"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />

                </div>
                <div className="form-outline mb-4">
                  <label className="form-label" >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />

                </div>

                {!submit &&(<button
                  className="btn bg-brand px-4 btn_hover gradient-custom-2 mb-3"
                  type="submit"
                >
                Create New
                </button>)}
                {submit &&(<button
                  className="btn bg-brand px-4 btn_hover gradient-custom-2 mb-3"
                  type="button"
                >
                Create New 
                <span class="spinner-border spinner-border-sm  mx-3" role="status">
                </span>
                </button>)}


               
              </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
