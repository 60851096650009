import React, { useEffect, useState } from 'react';
import Text from './Text';
import CardSecurity from './CardSecurity';
import Title from './Title';
import Select2 from './Select2';
import NotificationCard from './NotificationCard';
import RadioButton from './RadioButton';
import InputBox from './InputBox';
import CheckBox from './CheckBox';
import ProgressBar from './ProgressBar/ProgressBar';
import Footer from '../layout/Footer';
import { title } from 'process';
const ComponentRender = ({lang,setInputs, list,dependentList,inputs,handleChange,handleSave }) => {
const [stages, setStages] = useState(null);
const [currentStageIndex, setCurrentStageIndex] = useState(0);
const [partsComponent, setPartsComponent] = useState([]);

let initialStages=[];

const container = 'progress-bar-wrapper'; // Replace with your actual container name

  useEffect(()=>{
    let data=list.parts_component && list.parts_component.filter(x=>x.parent_id==null && x.component.type=="Forms" && x.component.id=="14");
    
    let current=true;
    data && data.map((item) => {
      let value="Steps";
      try {
        value = item.pcfv.find(x=>x.components_features.key==="title").value;
        if(lang=='es'){  value = item.pcfv.find(x=>x.components_features.key==="spanish")?.value || '';}

    } catch (error) {
        value = "Steps";
    }
  
  initialStages.push({id:item.id, title: value, visited: current, current: current })
  current=false;
    });

    setStages(initialStages)
    
    // updateComponentList()
  },[list]);

  function updateComponentList(i=null){

    let index=stages&& stages.findIndex((stage) => stage.current)
   if(index!=null && index!=-1)
   {
    let id=stages && stages[index].id
    if(id!=null)
    {
      let d=list.parts_component.filter(x=>x.parent_id==id);
      setPartsComponent(d)
    }
  }

  }
  const scrollToCurrentItem = () => {
    const currentItem = document.querySelector('.sidebar .current');
    if (currentItem) {
      const sidebar = currentItem.closest('.sidebar');
      if (sidebar) {
        sidebar.scrollTop = currentItem.offsetTop - sidebar.clientHeight / 2 + currentItem.clientHeight / 2;
      }
    }
  };
  useEffect(()=>{
    updateComponentList();
    handleSave()

  },[currentStageIndex]);

  if (!list || !list.parts_component) {
    return false;
  } 
  const handleCurrentIndex = (index) => {
    const currentIndex = index;
    if (  currentIndex >= 0 && currentIndex < stages.length  ) {
       const updatedStages = stages.map((stage, index) => ({
        ...stage,
        visited: index <= currentIndex,
        current: index === currentIndex ,
      }));
      setCurrentStageIndex(currentIndex)
     scrollToCurrentItem();
     setStages(updatedStages);
    }
   
    
  };
  const handleNext = () => {
    const currentIndex = stages.findIndex((stage) => stage.current);

    if (currentIndex < stages.length - 1) {
        setCurrentStageIndex(currentIndex+1)

      const updatedStages = stages.map((stage, index) => ({
        ...stage,
        visited: index <= currentIndex,
        current: index === currentIndex + 1,
      }));
      scrollToCurrentItem();


      setStages(updatedStages);
    }
  };

  const handlePrevious = () => {
    const currentIndex = stages.findIndex((stage) => stage.current);
    scrollToCurrentItem();

    if (currentIndex > 0) {
        setCurrentStageIndex(currentIndex-1)
      const updatedStages = stages.map((stage, index) => ({
        ...stage,
        visited: index < currentIndex - 1,
        current: index === currentIndex - 1,
      }));

      setStages(updatedStages);
    }
  };
  // console.log(list)

  
  return (
    <div className='row  ' >
      <div className='fixed-top col-md-2 d-md-block d-sm-none d-none' style={{ top: 87 }}>
        <div className='  text-light   steps_wrapper' style={{ paddingTop: 10 }}>
          <ProgressBar stages={stages} handleCurrentIndex={handleCurrentIndex} container={container} currentStageIndex={currentStageIndex} />
        </div>
      </div>
      <div className='col-md-2' style={{ backgroundColor: '#EEEEEE' }} >
      </div>
      <div className='col-md-10 ' style={{ backgroundColor: '#EEEEEE' }} >
        <h2 className=" mt-5" id="#top">{list.title}</h2>
        <div className='card rounded-0'>
          <div className='overview-layout row card-body'>
            {stages && list.parts_component && list.parts_component.filter(x => x.parent_id == null && x.component.type == "Forms" && x.component.id == "14").map((pc, index) => {
              
              return (
                <section className={stages.find(x => x.id == pc.id) && stages.find(x => x.id == pc.id).current ? "row" : "row hidden"} key={index}>
                  {list.parts_component.filter(x => x.parent_id == pc.id).sort((a, b) => a.display_order - b.display_order).map((item, index) => {
                    let title = item.pcfv.find(x => x.components_features.key === "title")?.value || '';
                    
                    if(lang=='es'){  title = item.pcfv.find(x => x.components_features.key === "spanish")?.value || '';}
                    let text=item.pcfv.find(x => x.components_features.key === "text")?.value||'';
                    if(lang=='es'){  text = item.pcfv.find(x => x.components_features.key === "spanish")?.value || '';}
        
                    return(
                    <React.Fragment key={index}>
                      {(item.component.title === "Select" && item.pcfv != null) && <Select2 dependent_name={(list.parts_component.find(part => part.id === item.pcfv[0].dependent) || { pcfv: [] }).pcfv.find(x => x.components_features.key === "name")?.value} dependent_value={item.pcfv[0].dependent_value}
                        colSize={(item.pcfv.find(x => x.components_features.key === "colSize") || {}).value || "col-md-12 "}
                        id={item.id} dependentList={dependentList} inputs={inputs} handleChange={handleChange} title={title} name={item.pcfv.find(x => x.components_features.key === "name").value} options={item.pcfv.find(x => x.components_features.key === "list").fmv.map(option => ({ value: option.key, label: option.value }))} />}
                      {(item.component.title === "Text" && item.pcfv != null) && <Text key={index} content={text} dependent_name={(list.parts_component.find(part => part.id === item.pcfv[0].dependent) || { pcfv: [] }).pcfv.find(x => x.components_features.key === "name")?.value} dependent_value={item.pcfv[0].dependent_value} inputs={inputs} />}
                      {(item.component.title === "Title" && item.pcfv != null) && <Title text={text} dependent_name={(list.parts_component.find(part => part.id === item.pcfv[0].dependent) || { pcfv: [] }).pcfv.find(x => x.components_features.key === "name")?.value} dependent_value={item.pcfv[0].dependent_value} inputs={inputs} />}
                      {(item.component.title === "CardSecurity" && item.pcfv != null) && <CardSecurity key={index} title={title} description={item.pcfv.find(x => x.components_features.key === "description").value} />}
                      {(item.component.title === "NotificationCard" && item.pcfv != null) && <NotificationCard dependent_name={(list.parts_component.find(part => part.id === item.pcfv[0].dependent) || { pcfv: [] }).pcfv.find(x => x.components_features.key === "name")?.value} dependent_value={item.pcfv[0].dependent_value} inputs={inputs} key={index} title={title} description={item.pcfv.find(x => x.components_features.key === "description").value} type={item.pcfv.find(x => x.components_features.key === "type").value} />}
                      {(item.component.title === "RadioButton" && item.pcfv != null) && <RadioButton lang={lang} inputs={inputs} handleChange={handleChange} key={index} type={(item.pcfv.find(x => x.components_features.key === "type")?.value) || 'Type 1'} colSize={(item.pcfv.find(x => x.components_features.key === "colSize")?.value) || 'col-md-12'} title={title} name={item.pcfv.find(x => x.components_features.key === "name").value} options={item.pcfv.find(x => x.components_features.key === "list").fmv.map(option => ({ value: option.key, label: (lang=='es')?option.spanish:option.value }))} dependent_name={(list.parts_component.find(part => part.id === item.pcfv[0].dependent) || { pcfv: [] }).pcfv.find(x => x.components_features.key === "name")?.value} dependent_value={item.pcfv[0].dependent_value} id={item.id} dependentList={dependentList} />}
                      {(item.component.title === "InputBox" && item.pcfv != null) && <InputBox inputs={inputs} handleChange={handleChange} key={index} title={title} colSize={item.pcfv.find(x => x.components_features.key === "colSize").value} type={item.pcfv.find(x => x.components_features.key === "type")?.value ||"text"} dependent_name={(list.parts_component.find(part => part.id === item.pcfv[0].dependent) || { pcfv: [] }).pcfv.find(x => x.components_features.key === "name")?.value} dependent_value={item.pcfv[0].dependent_value} id={item.id} dependentList={dependentList} name={item.pcfv.find(x => x.components_features.key === "name").value} />}
                      {(item.component.title === "Date" && item.pcfv != null) && <InputBox inputs={inputs} handleChange={handleChange} key={index} title={title} name={item.pcfv.find(x => x.components_features.key === "name").value} colSize={item.pcfv.find(x => x.components_features.key === "colSize").value} dependentList={dependentList} />}
                      {(item.component.title === "CheckBox" && item.pcfv != null) && <CheckBox id={item.id} inputs={inputs} handleChange={handleChange} key={index} title={title} name={item.pcfv.find(x => x.components_features.key === "name").value} colSize={item.pcfv.find(x => x.components_features.key === "colSize").value} dependentList={dependentList} dependent_name={(list.parts_component.find(part => part.id === item.pcfv[0].dependent) || { pcfv: [] }).pcfv.find(x => x.components_features.key === "name")?.value} dependent_value={item.pcfv[0].dependent_value} />}
                    </React.Fragment>)
                    
                  })}
                </section>
              )
            })}
          </div>
          <div className="row mb-5 mx-2">
            <div className="col-md-6">
              <a href="#top"><button className="btn btn-block btn-primary" onClick={handlePrevious}>Previous</button></a>
            </div>
            <div className="col-md-6">
              {(stages.length - 1) !== currentStageIndex ? (<a href="#top"><button className="btn btn-block btn-primary" onClick={handleNext}>Next</button></a>) : <a href="#top"><button className="btn btn-block btn-primary" onClick={() => { handleSave(true) }}>Save</button></a>}

            </div>
          </div>
        </div>
        <Footer style={{ marginTop: 30 }} />

      </div>

    </div>

  );
};

export default ComponentRender;