import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { baseUrl } from '../config';
const Profile = () => {
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState(localStorage.user!=null && JSON.parse(localStorage.user).name);
    const [password, setPassword] = useState('');
    const [responseMessage, setResponseMessage] = useState(''); // New state variable for the response message
    const [submit, setSubmit] = useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    setSubmit(true);
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = { method: "POST",  headers: myHeaders,redirect: "follow" };
    fetch(`${baseUrl}/api/profile/update?name=${name}&password=${password}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result)
console.log(result);
console.log(result)
          result=JSON.parse(result);

          if(result.status==200){
    
        
            const token = result.token;
            localStorage.setItem('user', JSON.stringify(result.data));
        setShowModal(false);

          }
          else
          {
            setResponseMessage(result.message)
          }
          setSubmit(false);
      })
      .catch((error) => {console.log("error", error);setSubmit(false);});

  };

    return (
        <div className='container'>
            <h1 className='my-4'>Profile</h1>
            <div className='container bg-white'>
                <div className='row'>
                    <div className='row col-4'>
                        <p>Full Name</p>
                        <h6>{localStorage.user!=null && JSON.parse(localStorage.user).name}</h6>
                    </div>
                    <div className='row col-4'>
                        <p>Email</p>
                        <h6>{localStorage.user!=null && JSON.parse(localStorage.user).email}</h6>
                    </div>
                   
                </div>
            </div>
            <div>
                <button type="button" className="btn btn-primary my-5" onClick={handleOpenModal}>
                    Update Profile
                </button>

                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={handleSubmit}>
                    {/* Display the response message */}
                    {responseMessage && (
                      <p className="text-center text-danger">{responseMessage}</p>
                    )}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form2Example11">
                        Name
                      </label>
                      <input
                        type="text"
                        id="form2Example11"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={handleNameChange}
                        required
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form2Example22">
                        Password
                      </label>
                      <input
                        type="password"
                        id="form2Example22"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                    <div className="text-center pt-1 mb-5 pb-1">
                    {!submit &&(<button
                      className="btn bg-brand px-4 btn_hover gradient-custom-2 mb-3"
                      type="submit"
                    >
                      Save
                    </button>)}
                    {submit &&(<button
                      className="btn bg-brand px-4 btn_hover gradient-custom-2 mb-3"
                      type="button"
                    >
                    Save 
                    <span class="spinner-border spinner-border-sm  mx-3" role="status">
                    </span>
                    </button>)}
                    </div>
                   
                  </form>
                    </Modal.Body>
                    
                </Modal>
            </div>
        </div>
    );
}

export default Profile