import React, { useEffect, useRef } from "react";

const ProgressBar = ({ stages,handleCurrentIndex, currentStage, container,currentStageIndex }) => {
  const stageWidth = 100 / stages.length;
  ProgressBar.singleStepAnimation = 50; // default value
let count=1;
  return (
    <div className={container}>
      
      <ul className="progress-bar custom-edittable-section">
        {stages.map((item, index) => (
          <li
            key={index}
            style={{cursor:'pointer'}}
            className={`section ${item.visited ? 'visited' : ''} ${item.current ? 'visited current' : ''}`}
            onClick={()=>{handleCurrentIndex(index)}}
          >
                  <div style={{marginTop:'10px'}}>
                  <p class="steps-info-text ">Step {count++}</p>
                  <p class="steps-headings">{item.title}</p>
                </div>
            
          </li>
        ))}
      </ul>
    </div>
  );
};


export default ProgressBar;
