// ItemCard.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../config';

const ItemCard = ({ item }) => {
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 py-3 px-3 item">
      <Link to={"/forms/" + item.id+'#top'}>
      <section
      className="elementor-section elementor-inner-section elementor-element elementor-element-3866c7d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="3866c7d"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-876ace7"
            data-id="876ace7"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-75b77b5 elementor-widget elementor-widget-witr_blog_section"
                  data-id="75b77b5"
                  data-element_type="widget"
                  data-widget_type="witr_blog_section.default"
                >
                  <div className="elementor-widget-container">
                    {/* Blog Section */}
                    <div className="witr_blog_area13">
                      <div className="blog_wrap blog13_idblog1 slick-initialized slick-slider">
                        <div
                          className="witr_all_mb_30 col-md-12 col-xs-12 col-sm-12 slick-slide slick-active"
                          data-slick-index={3}
                          aria-hidden="false"
                          style={{ width: 367 }}
                          tabIndex={-1}
                        >
                          <div
                            id="post-6899"
                            className="post-6899 post type-post status-publish format-standard has-post-thumbnail hentry category-it-solution"
                          >
                            <div className="busi_singleBlog">
                              {/* image */}
                              <div className="witr_sb_thumb">
                                <a
                                  href="https://demo.themexbd.com/wpt/roxien/how-will-you-know-success-when-it-shows-ups/"
                                  tabIndex={-1}
                                >
                                  {" "}
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    height="200"
                                    src={baseUrl + "/" + item.image}
                                    className="attachment-roxien-blog-default size-roxien-blog-default wp-post-image"
                                    alt=""
                                  />{" "}
                                </a>
                              </div>
                              <div className="all_blog_color">
                                <div className="witr_blog_con bs5">
                                  {/* title */}
                                  <h5>
                                    {item.title}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Blog Section */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      
      </Link>
    </div>
  );
};

export default ItemCard;
