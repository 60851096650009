// ProductList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';
import { baseUrl } from "../config";
import ItemCard from "../component/ItemCard";

const FormList = ({lang}) => {
  const [originalFormsList, setOriginalFormsList] = useState([]);
  const [filteredFormsList, setFilteredFormsList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [category, setCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/parts`, {
        params: {
          type_id: 1
        },
        headers: {
          "Content-Type": "application/json"
        }
      });
      setOriginalFormsList(response.data);
      setFilteredFormsList(response.data); // Initialize filtered list with all products
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/category`, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        setCategory(response.data)


      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, []);



  const handleSelectChange = (selectedOption) => {
    setSelectedSubCategory(null);
    setSearchInput("");
    if (selectedOption && selectedOption.value) {
    } else {
      fetchData();
    }
  };

  const handleSubCategoryChange = (selectedOption) => {
    setSelectedSubCategory(selectedOption);

    if (selectedOption && selectedOption.value) {
      // Update filtered list when a sub-category is selected
      const filteredProducts = originalFormsList.filter(item => item.category_id === selectedOption.value);
      setFilteredFormsList(filteredProducts);
      setCurrentPage(1); // Reset to the first page when sub-category changes
    } else {
      fetchData();
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filterItems = (items, query) => {
    return items.filter(item => item.title.toLowerCase().includes(query.toLowerCase()));
  };

  const handleSearch = (query) => {
    // Update filtered list when search input changes
    setFilteredFormsList(filterItems(originalFormsList, query));
    setCurrentPage(1); // Reset to the first page when search changes
  };
  // Update the displayed items based on the filtered and paginated list
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedFormsList = filteredFormsList.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredFormsList.length / itemsPerPage);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-d53d59d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="d53d59d"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-50be4e1"
              data-id="50be4e1"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-84718e0 elementor-widget elementor-widget-witr_section_title"
                    data-id={84718}
                    data-element_type="widget"
                    data-widget_type="witr_section_title.default"
                  >

                      <div className="witr_section_title">
                        <div className="witr_section_title_inner text-center">
                        <br/> 
                        <h2>
      {lang=="es"?'Presentar formulario en línea':'File Form Online'}

                        
                        </h2>

                          <h3>
      {lang=="es"?'Últimos formularios':'Latest Forms'}

                          
                          </h3>
                        <div className="container">
                          <div className="row mb-5 px-2 item-list">
                            {displayedFormsList.map(item => (
                              <ItemCard item={item} key={item.id} />
                            ))}
                          </div>
                          </div>

                          <div style={{ float: 'right', marginBottom: 200 }}>
                            {/* Pagination controls */}
                            <ul className="pagination p-0">
                              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={handlePrevClick} disabled={currentPage === 1}>
                                  <i class="fas fa-angles-left"></i>
                                </button>
                              </li>
                              {Array.from({ length: totalPages }).map((_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                  <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                  </button>
                                </li>
                              ))}
                              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={handleNextClick} disabled={currentPage === totalPages}>
                                  <i class="fas fa-angles-right"></i>

                                </button>
                              </li>
                            </ul>
                          </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

export default FormList;
