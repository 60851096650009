import React from 'react'

const CardSecurity = ({title,description}) => {
  return (
    <div className="section security-box">
    <div className="security-heading uscis-row"><i className="col-span-1 fa fa-lock security-icon"></i>
      <h2 className="col-span-8 security-heading-text">{title}</h2>
    </div>
    <div className="security-text">
      <p>
        {description}
      </p>
    </div>
  </div>
  )
}

export default CardSecurity
