import React, { useEffect, useState } from 'react';

const RadioButton = ({lang,dependent_name,dependent_value, title, name, options, inputs, handleChange,colSize="col-md-6",type }) => {
  const [hiddenClass,setHiddenClass]=useState();
  
  const handleRadioChange = (event) => {
    const value = event.target.value;
    handleChange({ target: { name, value } });
  };
  useEffect(()=>{
    if(dependent_name!=null && inputs!=null)
    {
    if(inputs[dependent_name]==dependent_value)
    {setHiddenClass('')}
    else{setHiddenClass('hidden')}
    }
  },[inputs])
  const selectClass = ` ${colSize}  ${hiddenClass}`;

  return (
    <>
    {type=="Type 1"&&( <div className={selectClass}>
      <div className='mui-input-wrapper'>
        <label className="mui-title">
        <p  dangerouslySetInnerHTML={{ __html: title }} style={{fontSize:'18px'}}></p>
        </label>
        <div className="row">
          {options !== "" && options.map((item, index) => (
            <div className="col-md-6 my-3" key={item.key}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name={name}
                  id={item.id}
                  value={item.value}
                  onChange={handleRadioChange}
                  
                  checked={inputs[name] === item.value}
                  
                />
                <label className="form-check-label mui-radio-option" htmlFor={item.key} dangerouslySetInnerHTML={{ __html: item.label  }}>

                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr/>
    </div>
)}
{type=="Type 2"&&( <div className={selectClass}>
  <div className='mui-input-wrapper'>
    <div className="row">
    <div className="col-md-6 my-3">
       <label className="mui-title">
       <p  dangerouslySetInnerHTML={{ __html: title }} style={{fontSize:'18px'}}></p>
       </label>
    </div>
    <div className="col-md-6 my-3 row">

      {options !== "" && options.map((item, index) => (
        <div className="col-md-6 " key={item.key}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={item.id}
              value={item.value}
              onChange={handleRadioChange}
              
              checked={inputs[name] === item.value}
              
            />
            <label className="form-check-label mui-radio-option" htmlFor={item.key}>
        <p  dangerouslySetInnerHTML={{ __html: item.label }}></p>

            </label>
          </div>
        </div>
      ))}
    </div>

    </div>
  </div>
  <hr/>
</div>
)}
{type=="Type 3"&&( <div className={selectClass}>
  <div className='mui-input-wrapper'>
    <div className="row">
    <div className="col-md-12 mt-2">
       <label className="mui-title">
       <p  dangerouslySetInnerHTML={{ __html: title }} style={{fontSize:'18px'}}></p>
       </label>
    </div>
    <div className="col-md-12  row">

      {options !== "" && options.map((item, index) => (
        <div className="col-md-3 " key={item.key}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={item.id}
              value={item.value}
              onChange={handleRadioChange}
              
              checked={inputs[name] === item.value}
              
            />
            <label className="form-check-label mui-radio-option" htmlFor={item.key}>
        <p  dangerouslySetInnerHTML={{ __html: item.label }}></p>

            </label>
          </div>
        </div>
      ))}
    </div>

    </div>
  </div>
  <hr/>
</div>
)}
    </>
     );
}

export default RadioButton;
