import React from 'react'
import { Link } from 'react-router-dom'
import About from './About'
import CampanyServices from './CampanyServices'
import OurTeam from './OurTeam'
import Contact from './Contact'

const HomeBody = ({lang}) => {
    return (
        <>
        
          <div className="page-content-home-page">
            <div
              data-elementor-type="wp-page"
              data-elementor-id={1717}
              className="elementor elementor-1717"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                
               {/* 
                <About/>
              <CampanyServices/>
            */}
                 
                <div>
                  {/*
                   <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-34a2eaf elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="34a2eaf"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-row">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-85057a4"
                          data-id="85057a4"
                          data-element_type="column"
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-795fc39 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="795fc39"
                                data-element_type="section"
                              >
                                <div className="elementor-container elementor-column-gap-default">
                                  <div className="elementor-row">
                                    <div
                                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ce7f70b"
                                      data-id="ce7f70b"
                                      data-element_type="column"
                                    >
                                      <div className="elementor-column-wrap elementor-element-populated">
                                        <div className="elementor-widget-wrap">
                                          <div
                                            className="elementor-element elementor-element-dc0a421 elementor-widget elementor-widget-witr_section_service"
                                            data-id="dc0a421"
                                            data-element_type="widget"
                                            data-widget_type="witr_section_service.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="em-service2 sleft all_color_service">
                                                <div className="em_service_content ">
                                                  <div className="em_single_service_text ">
                                                    <div className="service_top_image">
                                                      </div>
                                                      <div className="text_box">
                                                        <div className="service_top_text all_icon_color">
                                                          <div className="em-service-icon">
                                                            <img
                                                              decoding="async"
                                                              src="/theme/images/message.png"
                                                              alt=""
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="em-service-inner">
                                                          <div className="em-service-title">
                                                            <h3  style={{color:'#fff!important'}}>
                                                              Start Your Journey to
                                                              Better Business{" "}
                                                            </h3>
                                                          </div>
                                                          <div className="em-service-desc"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ef688e2"
                                        data-id="ef688e2"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-column-wrap elementor-element-populated">
                                          <div className="elementor-widget-wrap">
                                            <div
                                              className="elementor-element elementor-element-8ad3b4d roxien-star-rating--align-right roxien-star-rating-mobile--align-left roxien-star-rating--align-center elementor-widget elementor-widget-witr_section_button"
                                              data-id="8ad3b4d"
                                              data-element_type="widget"
                                              data-widget_type="witr_section_button.default"
                                            >
                                              <div className="elementor-widget-container">
                                                <div className="witr_button_area">
                                                  <div className="witr_btn_style mr">
                                                    <div className="witr_btn_sinner">
                                                      <a
                                                        href="https://demo.themexbd.com/wpt/roxien/#"
                                                        className="witr_btn contact_btn "
                                                        
                                                      >
                                                        Contact Now
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-9942b05 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="9942b05"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-row">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e7a53b5"
                          data-id="e7a53b5"
                          data-element_type="column"
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-22a1381 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="22a1381"
                                data-element_type="section"
                              >
                                <div className="elementor-container elementor-column-gap-default">
                                  <div className="elementor-row">
                                    <div
                                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ea24e83"
                                      data-id="ea24e83"
                                      data-element_type="column"
                                    >
                                      <div className="elementor-column-wrap elementor-element-populated">
                                        <div className="elementor-widget-wrap">
                                          <div
                                            className="elementor-element elementor-element-e396448 elementor-widget elementor-widget-witr_section_title"
                                            data-id="e396448"
                                            data-element_type="widget"
                                            data-widget_type="witr_section_title.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="witr_section_title">
                                                <div className="witr_section_title_inner text-left">
                                                  <h2>Why Choose Us</h2>
                                                  <h3>
                                                    Few Reasons Why You Should Choose
                                                    Us
                                                  </h3>
                                                  <p>
                                                    There are many variations of
                                                    passages of Lorem Ipsum available,
                                                    but the majority have suffered
                                                    alteration. If you are going to
                                                    use a passage of lorem Ipsum you
                                                    need.{" "}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-59bea6b elementor-widget elementor-widget-witr_progress_section"
                                            data-id="59bea6b"
                                            data-element_type="widget"
                                            data-widget_type="witr_progress_section.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="witr_single_progress all_color_bar">
                                                <div className="witr_title2">
                                                  <span className="witr_label">
                                                    Business Analysis (2017){" "}
                                                  </span>
                                                </div>
                                                <div className="progress witr_progress-style2 witr_progress-style13">
                                                  <div
                                                    className="progress-bar wow fadeInLeft animated animated"
                                                    data-wow-duration="1.5s"
                                                    data-wow-delay="0.2s"
                                                    style={{
                                                      width: "75%",
                                                      visibility: "visible",
                                                      animationDuration: "1.5s",
                                                      animationDelay: "0.2s",
                                                      animationName: "fadeInLeft"
                                                    }}
                                                  >
                                                    <span className="witr_percent">
                                                      75%
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-2b7e96b elementor-widget elementor-widget-witr_progress_section"
                                            data-id="2b7e96b"
                                            data-element_type="widget"
                                            data-widget_type="witr_progress_section.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="witr_single_progress all_color_bar">
                                                <div className="witr_title2">
                                                  <span className="witr_label">
                                                    Business Analysis (2018){" "}
                                                  </span>
                                                </div>
                                                <div className="progress witr_progress-style2 witr_progress-style13">
                                                  <div
                                                    className="progress-bar wow fadeInLeft animated animated"
                                                    data-wow-duration="1.5s"
                                                    data-wow-delay="0.2s"
                                                    style={{
                                                      width: "85%",
                                                      visibility: "visible",
                                                      animationDuration: "1.5s",
                                                      animationDelay: "0.2s",
                                                      animationName: "fadeInLeft"
                                                    }}
                                                  >
                                                    <span className="witr_percent">
                                                      85%
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-0bff6c0 elementor-widget elementor-widget-witr_progress_section"
                                            data-id="0bff6c0"
                                            data-element_type="widget"
                                            data-widget_type="witr_progress_section.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="witr_single_progress all_color_bar">
                                                <div className="witr_title2">
                                                  <span className="witr_label">
                                                    Finnancial growth (2019){" "}
                                                  </span>
                                                </div>
                                                <div className="progress witr_progress-style2 witr_progress-style13">
                                                  <div
                                                    className="progress-bar wow fadeInLeft animated animated"
                                                    data-wow-duration="1.5s"
                                                    data-wow-delay="0.2s"
                                                    style={{
                                                      width: "70%",
                                                      visibility: "visible",
                                                      animationDuration: "1.5s",
                                                      animationDelay: "0.2s",
                                                      animationName: "fadeInLeft"
                                                    }}
                                                  >
                                                    <span className="witr_percent">
                                                      70%
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-54e1c72 elementor-widget elementor-widget-witr_progress_section"
                                            data-id="54e1c72"
                                            data-element_type="widget"
                                            data-widget_type="witr_progress_section.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="witr_single_progress all_color_bar">
                                                <div className="witr_title2">
                                                  <span className="witr_label">
                                                    Investment growth (2020){" "}
                                                  </span>
                                                </div>
                                                <div className="progress witr_progress-style2 witr_progress-style13">
                                                  <div
                                                    className="progress-bar wow fadeInLeft animated animated"
                                                    data-wow-duration="1.5s"
                                                    data-wow-delay="0.2s"
                                                    style={{
                                                      width: "78%",
                                                      visibility: "visible",
                                                      animationDuration: "1.5s",
                                                      animationDelay: "0.2s",
                                                      animationName: "fadeInLeft"
                                                    }}
                                                  >
                                                    <span className="witr_percent">
                                                      78%
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7188736"
                                      data-id={7188736}
                                      data-element_type="column"
                                    >
                                      <div className="elementor-column-wrap elementor-element-populated">
                                        <div className="elementor-widget-wrap">
                                          <div
                                            className="elementor-element elementor-element-04ba53f elementor-widget elementor-widget-witr_section_video"
                                            data-id="04ba53f"
                                            data-element_type="widget"
                                            data-widget_type="witr_section_video.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="witr_play_vi witr_all_color_v">
                                                <div className="witr_about_image">
                                                  <img
                                                    decoding="async"
                                                    src="/theme/images/skill.png"
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="play-overlay ">
                                                  <a
                                                    className="video-popup video-vemo-icon venobox vbox-item"
                                                    data-vbtype="youtube"
                                                    data-autoplay="true"
                                                    href="https://www.youtube.com/watch?v=XHOmBV4js_E"
                                                  >
                                                    <i
                                                      aria-hidden="true"
                                                      className="fas fa-play"
                                                    />
                                                  </a>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                                                  </section>
              */}
                </div>
                {/*<OurTeam/>*/}
                <Contact lang={lang}/>
                 
           

                </div>
              </div>
            </div>
          </div>
       
        </>

    )
}

export default HomeBody
