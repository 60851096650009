import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { baseUrl } from '../config';

const ForgetPassword = () => {

    const navigate = useNavigate(); // Create a navigate function
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState(''); // New state variable for the response message
    const [submit, setSubmit] = useState(false);
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
      };
    
    const handleSubmit = async (e) => {
        setSubmit(true);
        e.preventDefault();
    
        var raw = JSON.stringify({
          email: email,
        });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        fetch(`${baseUrl}/api/password/reset/email`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            console.log(result)
    
              result=JSON.parse(result);
              if(result.status==200){
        
                setResponseMessage(result.message)
            
              }
              else
              {
                setResponseMessage(result.message)
              }
              setSubmit(false);
          })
          .catch((error) => {console.log("error", error);setSubmit(false);});
    
      };
    
  return (
    <section className="h-100 gradient-form" style={{ backgroundColor: '#eee' }}>
    <div className="container py-5 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-xl-10">
          <div className="card rounded-3 text-black">
            <div className="row g-0">
              <div className="col-md-6" >
                <div className="text-center my-auto" style={{height:'100%',display:'flex',alignItems:"center"}}>
                  <img
                    src="/logo.png"
                    alt="logo"
                    style={{ width: '400px' }}
                    className='text-center'
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-body p-md-5 mx-md-4">

                  <form onSubmit={handleSubmit}>
                    <p className='text-center'>Please provide your email address to initiate the password reset process.</p>
                    {/* Display the response message */}
                    {responseMessage && (
                      <p className="text-center text-danger">{responseMessage}</p>
                    )}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form2Example11">
                        Email
                      </label>
                      <input
                        type="email"
                        id="form2Example11"
                        className="form-control"
                        placeholder="Email address"
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                    </div>
                   
                    <div className="text-center pt-1 mb-5 pb-1">
                    {!submit &&(<button
                      className="btn bg-brand px-4 btn_hover gradient-custom-2 mb-3"
                      type="submit"
                    >
                      Send
                    </button>)}
                    {submit &&(<button
                      className="btn bg-brand px-4 btn_hover gradient-custom-2 mb-3"
                      type="button"
                    >
                    Send 
                    <span class="spinner-border spinner-border-sm  mx-3" role="status">
                    </span>
                    </button>)}
                      
                      &nbsp;
                      &nbsp;
                     
                    </div>
                    
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>  )
}

export default ForgetPassword