import React from 'react'
import { Link } from 'react-router-dom'
const Footer = ({lang}) => {
  return (
    <>
      <footer className=''>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-6'>
              <div className='text-center'>
                <h5 >Swift Immigration</h5>
                <p>
      {lang=="es"?'Protoss IT Sdn Bhd (subsidiaria de propiedad absoluta de e-print Solutions Sdn Bhd)':'Protoss IT Sdn Bhd ( Wholly-owned Subsidiary of e-print Solutions Sdn Bhd)'}

                
                </p>
      <address> 
      {lang=="es"?'Dirección: No.3 Lorong Brunei Tiga, frente a Jalan Pudu, 55100 Kuala Lumpur.':'Address: No.3 Lorong Brunei Tiga ,Off Jalan Pudu, 55100 Kuala Lumpur.'}
      
      
                </address>
                <div className='footer_contact text-center mt-4'>
                  <Link className='me-2' to={'Email: sales@e-print.my'}><i className="fas fa-envelope"></i> </Link>
                  <Link to={'  http://www.e-print.my'}><i className="fas fa-globe"></i></Link>
                </div>
              </div>
            </div>

            <div className='col'>
              <h5 >Links</h5>
              <div className='footer-link'>
                <Link to={'/'}>Ordering Info</Link>
                <Link to={'/'}>Contact Us</Link>
                <Link to={'/'}>
                  Site Map
                </Link>
                <Link to={'/'}>Privacy Policy</Link>
                <Link to={'/'}>Disclaimer</Link>
                <Link to={'/'}>Service</Link>
              </div>
              <div className='text-right mt-4 me-2'>
              </div>
            </div>
          </div>
          <p className='copyright_notice text-center mt-5'>Copyright©2023 e-print all rights reserved</p>

        </div>
      </footer>
    </>
  )
}

export default Footer