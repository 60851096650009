import React, { useEffect, useState } from 'react'
import MyCarousel from '../component/Home/MyCarousel'
import HomeBody from '../component/Home/HomeBody'
import FormList from './FormList'
import Slider from '../component/Slider'
import Footer from '../layout/Footer'
const Home = () => {
  const [lang,setLang]=useState('en');

  useEffect(() => {
    setLang(localStorage.getItem("lang"))
    
  });

  return (
    <div className='container-fluid px-0'>
    <Slider lang={lang}/>
    <FormList lang={lang}/>
    
      <HomeBody lang={lang}/>
      <Footer lang={lang}/>
    </div>

  )
}
export default Home