import React, { useEffect, useState } from 'react'

const CheckBox = ({dependent_name,dependent_value,id, title, name, colSize, inputs, handleChange,dependentList }) => {
  const [hiddenClass,setHiddenClass]=useState();

  let ischeck=(inputs[name]==name)?true:false;
  const handleCheckBoxChange = (event) => {
    ischeck=!ischeck;
    if(ischeck)
    {
    const value = event.target.value;
    handleChange({ target: { name, value } });
    // console.log({ target: { name, value } })

    }
    else{
      let value='';
    handleChange({ target: { name, value } });

    }
    if(dependentList!=null && dependentList.pcfv!=null){
      }

  };
  useEffect(()=>{
    if(dependent_name!=null && inputs!=null)
    if(inputs[dependent_name]==dependent_value)
    {setHiddenClass('')}
    else{setHiddenClass('hidden')}
  },[inputs])
  const selectClass = ` ${colSize} ${hiddenClass}`;
    return (
        <div className={selectClass}>

       
        <div className='form-check'>
            <input type="checkbox" className='form-check-input' name={name} onChange={handleCheckBoxChange} value={name}  checked={ischeck} />
            <label className="form-check-label">
                {title}
            </label>
        </div>
        </div>

    )
}

export default CheckBox