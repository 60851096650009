import React, { useEffect, useState } from 'react'

const Title = ({text,dependent_name,dependent_value,inputs}) => {
  const [hiddenClass,setHiddenClass]=useState();

  useEffect(()=>{
    if(dependent_name!=null && inputs!=null)
    if(inputs[dependent_name]==dependent_value)
    {setHiddenClass('with-margin-bottom-lg')}
    else{setHiddenClass('with-margin-bottom-lg hidden')}
  },[inputs])
  return (
    <div>
    <br/>
    <h3 id="overview-title " className={hiddenClass}>{text}</h3>
    <br/>

    </div>

  )
}

export default Title