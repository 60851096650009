import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const Redirect = () => {
  const navigate = useNavigate();

    setTimeout(() => {
      navigate("/login");
    }, 10);
  return (
    <div>Redirect</div>
  )
}

export default Redirect