import { BrowserRouter, Route, Routes, useNavigate  } from "react-router-dom";

import SignUp from "./screen/SignUp";
import Login from "./screen/Login";
import Layout from "./layout/Layout";
import Home from "./screen/Home";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "./config";
import FormFillingScreen from "./screen/FormFillingScreen";
import MyOrders from "./screen/MyOrders";
import Profile from "./screen/Profile";
import VerifyEmail from "./screen/VerifyEmail";
import ForgetPassword from "./screen/ForgetPassword";
import Redirect from "./component/Redirect";
import PDFScreen from "./screen/PDFScreen";
import AboutScreen from "./screen/AboutScreen";
import CompanyServicesScreen from "./screen/CompanyServicesScreen";
import OurTeamScreen from "./screen/OurTeamScreen";
import ContactScreen from "./screen/ContactScreen";
import FileFormScreen from "./screen/FileFormScreen";
const ScrollToTop = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return null;
};
function App() {
  // const navigate = useNavigate();
 
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [lang,setLang]=useState();

  useEffect(() => {
    

    if(localStorage.getItem('lang')==null || localStorage.getItem('lang')==undefined)
      {
        localStorage.setItem("lang",'en')
        setLang('en');
      }
      else{
        setLang(localStorage.getItem("lang"));
      }
    if(localStorage.getItem('token')==null || localStorage.getItem('token')==undefined)
      {
          // localStorage.removeItem("token");
          setIsAuthenticated(false);
      }
      else{
        setToken(localStorage.getItem("token"));
        setIsAuthenticated(true);
      }
    
  });

  const [menu,setMenu]=useState(null)

  const getMenu = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/menu`, { headers: { "Content-Type": "application/json" } });
        setMenu(response.data);
      } catch (error) { console.log(error); }
    };
    useEffect(()=>{
      getMenu()
    },[])
  

  return (
    <BrowserRouter>
    <ScrollToTop />

      <Layout lang={lang}  setLang={setLang} menu={menu} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
        <Routes>
          <Route path="/" element={<Home />} layout="default" />
          
          {/*
          <Route path="/about" element={<AboutScreen />} layout="default" />
          <Route path="/company-services" element={<CompanyServicesScreen />} layout="default" />
          <Route path="/our-member" element={<OurTeamScreen />} layout="default" />
      */}
      <Route path="/Home" element={<Home lang={lang} />} layout="default" />

          <Route path="/contact" element={<ContactScreen lang={lang} />} layout="default" />
          <Route path="/file-form" element={<FileFormScreen lang={lang}/>} layout="default" />

          <Route path="/forms/:id/" element={isAuthenticated ? <FormFillingScreen token={token} lang={lang} /> : <Redirect />} layout="default" />


          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} layout="default"  />
          <Route path="/register" element={<SignUp />} layout="default" />
          <Route path="/applications" element={isAuthenticated ? <MyOrders lang={lang} token={token} /> : <Redirect />} layout="default" />
          <Route path="/profile" element={isAuthenticated ? <Profile token={token} /> : <Redirect />} layout="default" />
          <Route path="/verify/email/:id" element={<VerifyEmail/>} layout="default" />
          <Route path="/forget/password" element={<ForgetPassword/>} layout="default" />



        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
