import React, { useEffect, useState } from 'react'

const InputBox = ({dependent_name,dependent_value, title, name, colSize,type, inputs, handleChange }) => {
    const [hiddenClass,setHiddenClass]=useState();
    useEffect(()=>{
        if(dependent_name!=null && inputs!=null)
        {
        if(inputs[dependent_name]==dependent_value)
        {setHiddenClass('')}
        else{setHiddenClass('hidden')}
        }
      },[inputs])
      const selectClass = ` ${colSize}  ${hiddenClass}`;

    return (
        <div className={selectClass}>
        {
            (type=="text" ||type=="") &&(
                <div className='mui-input-wrapper'>
                <label className="mui-label">
                    {title}
                </label>
                <div>
                    <input type="text" name={name} onChange={handleChange} value={inputs[name] || ''}  />
                </div>
            </div> 
            )
        }
        {
            type=="number" &&(
                <div className='mui-input-wrapper'>
                <label className="mui-label">
                    {title}
                </label>
                <div>
                    <input type="number" name={name} onChange={handleChange} value={inputs[name] || ''}  />
                </div>
            </div> 
            )
        }
        {
            type === "textarea" && (
                <div className="row">
                    <div className="col-12">
                        <div className='mui-input-wrapper'>
                            <label className="mui-label">
                                {title}
                            </label>
                            <div>
                                <textarea 
                                    name={name} 
                                    onChange={handleChange} 
                                    value={inputs[name] || ''}
                                    className="form-control"
                                    rows="5" // Set the rows attribute to 5 for a textarea with 5 rows
                                ></textarea>
                            </div>
                        </div> 
                    </div>
                </div>
            )
        }
        
        
        </div>

    )
}

export default InputBox