import React, { useEffect, useState } from 'react'

const Text = ({content,dependent_name,dependent_value,inputs}) => 
{
  const [hiddenClass,setHiddenClass]=useState();

  useEffect(()=>{
    if(dependent_name!=null && inputs!=null)
    if(inputs[dependent_name]==dependent_value)
    {setHiddenClass('')}
    else{setHiddenClass('hidden')}
  },[inputs])
  return (<div className={hiddenClass}>
    <p  dangerouslySetInnerHTML={{ __html: content }} style={{fontSize:'18px'}}></p>
    </div>)
}

export default Text