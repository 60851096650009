import React from 'react'
import FormList from './FormList'
import Footer from '../layout/Footer'

const FileFormScreen = ({lang}) => {
  return (
    <div className=''>
    <FormList lang={lang}/>
    <Footer/>

    </div>
  )
}

export default FileFormScreen