import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../config';

const Header = ({lang,setLang,menu,isAuthenticated,setIsAuthenticated}) => {
  // alert(isAuthenticated)
  const [language, setLanguage] = useState('en'); // Default language is English
  const toggleLanguage = () => {
    let l=language === 'en' ? 'es' : 'en';
    setLanguage(l); // Toggle between English ('en') and Spanish ('es')
    setLang(l)
    localStorage.setItem("lang",l)

  };
  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    setIsAuthenticated(false)
       setTimeout(() => {navigate("/login");}, 10);
  }
  
 

  const renderSubMenuItems = (subMenuItems) => {
    return subMenuItems.map((subMenuItem) => (
      <Link key={subMenuItem.id} className="dropdown-item" to={subMenuItem.url}>
        {subMenuItem.title}
      </Link>
    ));
  };

  const renderMenuItems = (menuItems) => {
    return menuItems.map((menuItem) => (
      <li key={menuItem.id} className={`nav-item ${menuItem.sub_menu_item.length>0 ? 'dropdown' : ''}`}>
        <Link
          to={menuItem.sub_menu_item.length>0  ? '#' : menuItem.url}
          className={`nav-link ${menuItem.sub_menu_item.length>0  ? 'dropdown-toggle' : ''}`}
          id={`navbarDropdown${menuItem.id}`}
          data-toggle={menuItem.sub_menu_item.length>0  ? 'dropdown' : ''}

        >
          {menuItem.title}
        </Link>
        {menuItem.sub_menu_item.length>0  && (
          <div className="dropdown-menu rounded-0" aria-labelledby={`navbarDropdown${menuItem.id}`}>
            {renderSubMenuItems(menuItem.sub_menu_item)}
          </div>
        )}
      </li>
    ));
  };

  return (
    <div>
 
        <nav className="navbar navbar-expand-lg fixed-top navbar-light px-4 border-bottom" style={{backgroundColor:'#fff'}}>
          <Link className="navbar-brand" to={'/'}><img src="/logo.png" style={{height:'50px!important',width:150}} alt="Logo" /></Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <Link className="nav-link" to="/">{lang=="es"?'Hogar':'Home'}</Link>
              <Link className="nav-link" to="/file-form"> {lang=="es"?'Presentar formulario en línea':'File Form Online'}</Link>
              
              {/*
              <Link className="nav-link" to="/about">About Swift</Link>
              <Link className="nav-link" to="/our-member">Our Member</Link>
              <Link className="nav-link" to="/company-services"> Company Services</Link>
             */}
              
              {menu!=null && renderMenuItems(menu.menu_items)}
              { isAuthenticated && (
                <li className="nav-item">
                <Link className="nav-link" to="/applications">{lang=="es"?'Aplicaciones':'Applications'} </Link>
              </li>
              )}
              <Link className="nav-link" to="/contact">{lang=="es"?'Consulta gratis':'Free Consultation'} </Link>

              
      
              
            </ul>
          </div>
          <Link className="nav-link mx-2" onClick={toggleLanguage}>{language === 'en' ? <img src="/images/es-flag.png" width="60" alt="Spanish"/> : <img src="/images/en-flag.png" width="60" style={{float:'end'}} alt='English'/>} </Link>

          <div className='lr_button_nav'>
          {
            !isAuthenticated && (
            <div>
              <Link to={'/login'}>
              
                <button className='btn btn-primary btn-sm me-2'>{lang=="es"?'Acceso':'Login'}</button>
              </Link>
              <Link to={'/register'}>
                <button className='btn btn-primary btn-sm'>{lang=="es"?'Registro':'Register'}</button>
              </Link>
              
            </div>)}

            {isAuthenticated && (
              <div className='User_icon text-center'>

                <div className="dropdown-toggle" style={{cursor:'pointer'}} data-toggle="dropdown" aria-expanded="false">
                  <i className='fas fa-user-tie me-2'></i>
                  {localStorage.user!=null && JSON.parse(localStorage.user).name}
                </div>
                <div className="dropdown-menu " style={{ right: '10px' }}>
                  <Link style={{borderBottom:'1px solid #ddd'}} className='d-block'><i className='fa-solid fa-wind  py-2 mx-3'></i>{lang=="es"?'Usuario':'User'} </Link>
                <br/>

                  <Link to={'/profile'} style={{borderBottom:'1px solid #ddd'}} className='d-block' ><i class="fa-solid fa-id-card-clip  pb-2 mx-3" ></i>{lang=="es"?'Perfil':'Profile'} </Link>
                  <br/>

                  <Link onClick={logout}  className='d-block'>
                  <i class="fa-solid fa-arrow-right-from-bracket pb-2 mx-3"></i>{lang=="es"?'Cerrar sesión':'Logout'} 
                  </Link>

                </div>
              </div>)}
          
          </div>
        </nav>
      </div>
  );
};

export default Header;
