import React, {useState, useEffect } from 'react';

const NotificationCard = ({dependent_name,dependent_value,title,description,type,inputs}) => {
  const [hiddenClass,setHiddenClass]=useState();

  useEffect(()=>{
    if(dependent_name!=null && inputs!=null)
    if(inputs[dependent_name]==dependent_value)
    {setHiddenClass('')}
    else{setHiddenClass('hidden')}
  },[inputs])
  const alertClass = `uscis-alert uscis-alert-${type} max-width-alert ${hiddenClass}`;
  return (
    <div className={alertClass} >
      <div className="uscis-alert-title" >{title} </div>
      <div className="uscis-alert-content no-padding-top">
      <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
  );
};

export default NotificationCard;
