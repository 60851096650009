import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import axios from 'axios';
import { baseUrl } from "../config";
import ComponentRender from "../component/ComponentRender";

const FormFillingScreen = ({lang}) => {
  const navigate = useNavigate();

  let { id } = useParams();
  const [product, setProduct] = useState({});
  const [inputs, setInputs] = useState({});
  const [isSave, setIsSave] = useState(false);
  const [formsDataFeild, setFormsDataFeild] = useState([]);
  const [Order, setOrder] = useState({});

  var myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem('token'));
  var requestOptions = { method: "POST",  headers: myHeaders,
  redirect: "follow" };

  const extractValuesFromString = (string) => {
    const pattern = /\((.*?)\)/g;
    const matches = string.match(pattern);
  
    if (matches && matches.length >= 2) {
      const value1 = matches[0].slice(1, -1);
      const value2 = matches[1].slice(1, -1);
      const value3 = matches.length >= 3 ? matches[2].slice(1, -1) : '';
      return [value1, value2, value3];
    } else {
      return null;
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(event)

    setInputs((values) => ({ ...values, [name]: value }));
    setIsSave(false);
    if(name=="eligibilityCategory")
    {
      let eligibilityCategory=extractValuesFromString(value.split(' ')[0]);
      if(eligibilityCategory)
      setInputs((values) => ({ ...values, section_1: eligibilityCategory[0] }));
      setInputs((values) => ({ ...values, section_2: eligibilityCategory[1] }));
      setInputs((values) => ({ ...values, section_3: eligibilityCategory[2] }));

    }
  };



  const getProduct = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/parts/readById/${id}`, { headers: { "Content-Type": "application/json" } });
      setProduct(response.data);
    } catch (error) { console.log(error); }
  };

  const getFormsDataFeild = () => {
    var requestOptions = { method: "GET", redirect: "follow" };
    fetch(`${baseUrl}/api/formsDataFeild?form_id=${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setFormsDataFeild(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));
  };

  const getOrder = () => {
    
    fetch(`${baseUrl}/api/order/readByProductId/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {

        let r=JSON.parse(result);

        if (r.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          setTimeout(() => { navigate("/login"); }, 10);
        }
        else
        {
          let data = JSON.parse(result).data.order_data;
          setOrder(JSON.parse(result).data);
          data.map((item) => {
            setInputs((values) => ({ ...values, [item.pcfv.value]: item.value }));
            return null;
          });
        }
       
      })
      .catch((error) => console.log("error", error));
  };
  const handleSave = (s=false) => 
  {
      var formsUserDataList = [];
      Object.entries(inputs)
        .map(([key, value]) => {
          // console.log(formsDataFeild)
          const o = formsDataFeild.find((x) => x.value == key);
          // 485
          if (o != null) {
            let pdfName=null;

            if(o.parts_component.component_id==7)
            {
              try {let fmv=formsDataFeild.find(x=>x.pc_id==o.pc_id && x.components_features.key=="list").fmv;pdfName=fmv.find(x=>x.key==value).pdfName;} catch (error) {}
            }
            else
            {
              try {pdfName=formsDataFeild.find(x=>x.pc_id==o.pc_id && x.components_features.key=="pdfName").value;} catch (error) {}
            }
           
            formsUserDataList.push({ pcfv_id: o.id, value,pdfName:pdfName });
          }
        })
        .filter((item) => item !== null);

      var raw = JSON.stringify({
        order_id:Order.id,
        formsUserDataList: formsUserDataList
      });
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      // console.log(formsUserDataList);
      // return "ok";
      fetch(`${baseUrl}/api/order/store`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result)

            result=JSON.parse(result);
            if(result.status==200){
              if(s)
              {
                alert('Form Saved Successfully')
                navigate("/applications");
              }
            }
           

        })
        .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getProduct();
    getFormsDataFeild();
    getOrder();
  }, [])
  return (
    <div className="container-fluid">
      <div className="row">
        
        <div className="col-md-12 ">
          <div className="">
            <div className="">
              {product != "" && (
                <ComponentRender
                  list={product}
                  inputs={inputs}
                  setInputs={setInputs}
                  lang={lang}
                  dependentList={
                    product && product.parts_component
                      ? product.parts_component.filter((x) => x.pcfv[0].is_depend === 1)
                      : []
                  }

                  handleChange={handleChange}
                  handleSave={handleSave}
                />
              )}
            </div>
          </div>
        </div>
     {/*
        <div className="col-12">
          <div className="card rounded-0">
            <div className="card-body rounded-0">
              <button className="btn bg-brand w-100 rounded-1 " onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
                */}

      </div>

    </div>
  )


};

export default FormFillingScreen;
