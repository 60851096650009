// Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {useLocation } from 'react-router-dom';

const Layout = ({lang,setLang, children,menu,isAuthenticated,setIsAuthenticated }) => {  
  
  const location = useLocation();
  var _layout="default"
  try {
    // _layout=children.props.children.find(x=>x.props.path===location.pathname).props.layout;
  } catch (error) {
    _layout="pdf";
    
  }
  return (
    
    <div>
    {_layout=="default" && <div> <Header setLang={setLang} lang={lang} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/> <main style={{marginTop:80}} >{children}</main> </div> }
    {_layout=="pdf" && <div><main>{children}</main></div> }
    {_layout=="pdfheader" && <div><Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} /><main>{children}</main></div> }

    
    </div>
  );
};

export default Layout;
